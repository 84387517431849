<template>
  <div class="layout_common">
    <div class="main_box">
      <div class="main_box_con">
        <searchHead
          compType="customer-unpack-detail"
          :storeInfo="storeInfo"
          @search="search"
          @reset="search"
          @rankClick="rankClick"
        />
        <tableComp
          v-loading="loading"
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
          <el-table-column slot="goodInfo" label="类目/品牌/型号">
            <template v-slot="scope">
              <span>
                {{ scope.row.categoryName || '-' }} /
                {{ scope.row.brandName || '-' }} /
                {{ scope.row.modelName || '-' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column slot="rate" label="不良率">
            <template v-slot="scope">
              {{ scope.row.rate + '%' }}
            </template>
          </el-table-column>
           <el-table-column slot="packCount" label="开箱不良(台)">
            <template v-slot="scope">
              <div
                v-if="scope.row.packCount"
                style="line-height:42px;cursor:pointer;color:#385BFF"
                @click="countDetail(scope.row)"
              >
                {{ scope.row.packCount }}
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
        </tableComp>
      </div>
    </div>
    <!-- 商家发货排名 -->
    <rank :isShow="isShow" @close="close" @selectStore="selectStore" />
    <countDetail :isShow="detailShow" @close="close" :orderCode="orderCode" />
  </div>
</template>

<script>
import searchHead from '@/views/data-center/components/searchHead'
import rank from '@/views/data-center/components/rank'
import countDetail from '@/views/data-center/components/countDetail'
import { deliverDetailList } from '@/api'
export default {
  components: { searchHead, rank, countDetail },
  data () {
    return {
      loading: false,
      detailShow: false,
      isShow: false,
      orderCode: '',
      curPage: 1,
      pageSize: 10,
      total: 0,
      storeInfo: {},
      tableData: [],
      orderType: 1,
      timeType: 3,
      storeId: '',
      storeName: '',
      commodityName: '',
      startTime: '',
      endTime: '',
      theadName: [
        { slot: 'rate' },
        { prop: 'orderCode', label: '订单号' },
        { prop: 'storeName', label: '商家' },
        { prop: 'commodityName', label: '商品名称' },
        { slot: 'goodInfo' },
        { prop: 'deliveryTime', label: '发货时间' },
        { prop: 'deliveryNum', label: '发货数量(台)' },
        { prop: 'repairCount', label: '报修数量(台)' },
        { slot: 'packCount' }
      ]
    }
  },
  mounted () {
    const { timeType, startTime, endTime } = this.$route.query
    this.timeType = +timeType
    this.startTime = startTime
    this.endTime = endTime
    this.getList()
  },
  computed: {},
  methods: {
    getList () {
      const { customerName } = this.$route.query
      const params = {
        customerName,
        curPage: this.curPage,
        pageSize: this.pageSize,
        storeId: this.storeId,
        commodityName: this.commodityName,
        orderType: this.orderType,
        timeType: this.timeType,
        startTime: this.startTime,
        endTime: this.endTime
      }
      this.loading = true
      deliverDetailList(params).then(res => {
        this.loading = false
        const { list, totalCount } = res.data
        this.tableData = Object.freeze(list)
        this.total = Object.freeze(totalCount)
      })
    },
    // 点击发货量排名
    rankClick (date) {
      this.isShow = true
      // 累计发货量默认是传空， 月度 默认是当前月
      this.curDate = date
    },
    // 选择的商家
    selectStore (val) {
      this.storeId = val.storeId
      this.storeName = val.storeName
      this.storeInfo = {
        storeId: this.storeId,
        storeName: this.storeName
      }
    },
    // 开箱不良明细
    countDetail ({ orderCode }) {
      this.orderCode = orderCode
      this.detailShow = true
    },
    close () {
      this.detailShow = false
      this.isShow = false
    },
    search (val) {
      this.curPage = 1
      const {
        commodityName,
        storeId,
        orderType,
        timeType,
        startTime,
        endTime
      } = val
      this.commodityName = commodityName
      this.storeId = storeId
      this.orderType = orderType
      this.timeType = timeType
      this.startTime = startTime
      this.endTime = endTime
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
